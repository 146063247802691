import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import { Badge, Card, CardBody} from 'reactstrap'
import Img from 'gatsby-image'
import { slugify, getStylefromString, fixNames } from '../util/utilityFunctions'
import React from 'react'
import VideoPlayer from '../components/VideoPlayer'
import NotFoundPage from '../pages/404'
import {DiscussionEmbed} from 'disqus-react'

import '../styles/index.scss'

const SinglePost = ( {data, pageContext} ) => {
    const post = data.markdownRemark.frontmatter

    const baseUrl = "http://www.LOnWheels.us/"
    const disqusShortname = 'lonwheels'
    const disqusConfig = {
        identifier: data.markdownRemark.identifier,
        title:post.title,
        url:baseUrl+pageContext.slug
    }

    if(post.type === "hidden")
    {
        return NotFoundPage()
    }

    var image = <Img className="card-image-top" fluid={post.image.childImageSharp.fluid}/>

    if(post.image.name === "empty")
    {
        image = <div></div>
    }

    if(post.type === "video")
    {
        image = VideoPlayer({
            videoID:post.videoID,
            t_width:"100%",
            t_height:"500",
            tframeborder:"0"})
    }

    let _tags = []

    post.tags.map(tag => {

        var style = getStylefromString(tag);

        _tags.push(
            <Link to={`/tags/${slugify(tag)}`}>
                <Badge color={style} className="text-uppercase" pill>{fixNames(tag)}</Badge>
            </Link>
        )
    });

    return(
        <Layout pageTitle = {post.title}>
            <SEO title={post.title}/>
                    <Card>
                        {image}
                        <CardBody>
                            <div dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
                            <ul className="post-tags">
                                {_tags}
                            </ul>
                            <span className="text-info float-right">{post.date}</span>
                        </CardBody>
                    </Card>
                    <h4 className="text-center">
                        Share this post
                    </h4>
                    <div className="social-share-links text-center">
                        <ul>
                            <li>
                                <a href={'https://www.facebook.com/sharer/sharer.php?u='+
                                baseUrl+pageContext.slug}
                                className="facebook"
                                target="_blank"
                                rel="noopener noreferrer">
                                    <i class="fab fa-facebook fa-2x"/>
                                </a>
                            </li>
                            <li>
                                <a href={'https://www.twitter.com/share?url='+
                                baseUrl+
                                pageContext.slug+
                                '&text='+
                                post.title+
                                '&via'+
                                'twitterHandle'
                                }

                                 className="twitter"
                                target="_blank"
                                rel="noopener noreferrer">
                                    <i class="fab fa-twitter fa-2x"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <DiscussionEmbed shortname={disqusShortname} config={disqusConfig}/>
        </Layout>
        
    )
}

export const postQuery = graphql`
query blogPostBySlug($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        author
        date(formatString: "MMM DD YYYY")
        tags
        type
        videoID
        image{
            name
                  childImageSharp{
                      fluid(maxWidth: 700){
                          ...GatsbyImageSharpFluid
                      }
                  }
              }
      }
      fields {
        slug
      }
    }
  }
`

export default SinglePost